/* Main */
.App {
  margin: auto;
}

/* Header */
.App-header {
  background-color: darkslategray;
  height: 100px;
}

.headerContainer {
  margin: auto;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.headerTextContainer {
  float: right;
  display: flex;
  width: 325px;
  justify-content: space-between;
  height: 50px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.headerTextStyle {
  color: black;
  text-decoration-line: none;
}

/* Content */
.App-content {
  margin: auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
}

.techHeaders {
  font-size: 1.62671rem;
  text-decoration-line: underline;
  margin-bottom: 0px;
  padding-top: 10px;
}

.linkStyle {
  text-decoration: none;
}

/* Footer */
.App-footer {
  margin: auto;
  max-width: 960px;
  padding-top: 10px;
}

.footerText {
  font-size: 12px;
  line-height: 1.5;
}

/* Fonts */
.mainTitle-font {
  color: white;
  text-decoration-line: none;
  margin-top: 10px;
  font-size: 30pt;
}

.directory-font {
  color: white;
  text-decoration-line: none;
  font-size: 24pt;
}

.directory-font:hover {
  text-decoration-line: overline;
}

p {
  font-size: 14pt;
  margin-bottom: 15px;
}